/* Conteneur principal de la galerie */
.gallery-section {
  text-align: center;
  width: 90%;
  margin: 0 auto;

  h2 {
    margin: 30px 0 50px 0;
  }
}

/* Conteneur de Swiper */
.gallery-container {
  width: 100%;
  height: auto; /* Ajuste la hauteur automatiquement */
}

/* Styles des diapositives individuelles */
.project-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styles des images des projets */
.project-image {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

/* Styles des images des projets */
.project-image img {
  width: 100%;
  height: auto; /* Permet à l'image de s'adapter en hauteur */
  max-width: 100%;
  transition: transform 0.3s ease;
}

/* Styles des légendes des images */
.project-image figcaption {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  background-color: rgba(45, 45, 45, 0.455);
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  font-size: 14px;
  text-align: center;
  transition: opacity 0.3s ease;
}

/* Effet d’agrandissement pour l’image centrale */
.swiper-slide-active .project-image {
  transform: scale(1.1);
}

/* Responsivité pour les tablettes et les mobiles */
@media (max-width: 768px) {
  .project-image figcaption {
    bottom: 5px;
    width: calc(100% - 20px);
    height: auto; /* Ajustement pour les petits écrans */
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .project-image figcaption {
    font-size: 10px;
  }
}

.swiper-3d .swiper-slide {
  height: auto; /* Ajuste la hauteur pour les diapositives 3D */
}

.swiper-wrapper {
  height: auto; /* Ajuste la hauteur du wrapper */
}

.swiper {
  height: auto; /* Ajuste la hauteur globale du swiper */
}

.swiper-button-next,
.swiper-button-prev {
  color: $darkgreen !important; /* Couleur forcée */
}
