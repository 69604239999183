/*menu*/
.menu{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100px;
    padding: 0;
    margin: 0;

    @include tablet{
        background: none;
        margin: 10px;
        height: auto;
    }

    &__list{
        display: flex;
        margin: 0; 
        align-items: flex-end; 
        gap: 30px;
        padding-right: 20px;

        @include tablet{
            gap: 20px;
        }

    }

    &__item{
        list-style: none;
        display: flex;
        padding: 0 8px;
        align-items: flex-start;
        justify-content: center;
        width: 100px;
        height: 40px;

        @include mobile {
            width: 100%;
        }

        &:hover {
            border-bottom: 5px solid $white;
        }
        
        &-link{
            text-decoration: none;
            color: $white;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

/*Menu burger*/
.menu {

        .close-button {

            top: 70px;
            z-index: 100;
            color: $black;
            width: 30px;
            height: 30px;
            right: 30px;
            border-radius: 5px;
            border: none;
            background: $white;
            &::after {
                border-radius: 5px;
            }

            @include mobile{
                top: 50px;
            }

            }
        
        &__burger {
            display: none;

            @include tablet{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border-radius: 5px;
                cursor: pointer;
            }
            
            &::after {
                border-radius: 5px;
            }
        }

        &__list{

            @include tablet {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                position: absolute;
                background: $black;
                top: 100px;
                right: 0;
                opacity: 0.9;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                max-height: 0;
                overflow: hidden;
                //transition: max-height 0.3s;
                

                @include tablet{
                    top: 80px;
                }

                @include mobile {
                    top: 60px;
                }
                
                &.open{
                    max-height: 100vh;
                    padding-top: 40px;
                    width: 100%;
                    height: 100vh;
                    z-index: 99;
                    
                    @include mobile{
                        //height: 400px;
                    }
                }
            }
        }

        &__item{
            //width: 100%;

            @include tablet {
                height: 35px;
                width: 100%;
            }

            &:hover {
                @include tablet{
                    border-bottom: none;
                }
            }
            
            &-link{
                width: 100%;

                @include tablet {
                    background: none;
                    color: $white;
                }
            }
        }
    }
