
.resource-section{
    display: flex;
    justify-content: space-between;
    //background: $darkgreen;
    padding: 50px;


    @include mobile{
        padding: 20px;
        flex-direction: column;
        gap: 20px;
        margin-top: 100px;
    }

}

.resource-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 30%;
    max-height: auto;
    //background-color: $darkgreen;
    color: $black;
    //border: 2px solid black;
    border-radius: 20px;
    padding: 20px;
    gap: 30px;

    @include mobile{
        width: 90%;
    }

    p{
        margin: 0;
    }

    h3{
        text-align: center;
        margin: 10px;
    }

}

.resource-image{
    width: 100%;
    height: 200px;
}