.quisuisje{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;

    @include mobile{
        margin-top: 150px;
    }

    &__container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.timeline-cycle{
    width: 80%;
}