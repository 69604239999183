/* Style pour le formulaire */
.container__form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}
.contact__form {
    background-color: $darkgreen;
    margin-bottom: 50px;
    border-radius: 10px;
    width: 80%;

    @include tablet{
        width: 100%;
    }

    @include mobile{
        width: 90%;
    }

    h2 {
    color: $green;
    }
}
.form {

    @include form;
    width: 80%;
    margin: 40px;

    @include mobile {
        flex-direction: column;
        align-items: center;
    }

    &-group{
        display: flex;
        flex-direction: column;
        width: calc(50% - (10px));

        @include mobile {
            width: 100%;
        }

        label {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-weight: bold;
            margin-bottom: 8px;
            //opacity: 0.7;
          }

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        select,
        textarea {
        
            padding: 10px;
            margin-bottom: 15px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 16px;
        }
    }

    .form-style{
        width: 100%;
    }
}

.error-message{
    color: $violet;
}

.confirmation-message{
    padding: 0 30px;
    p{
        color: $white;
    }
    p:first-child{
        opacity: 1;
        font-weight: bold;

    }
}