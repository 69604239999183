.pagevente__section-card{
    background: $black;

    h2{
        color: $white;
        margin-top: 50px;
    }

    .container__card-div {
        display: flex;
        //height: 400px;
        align-items: center;
        justify-content: space-around;
        margin: 50px;

        @include mobile{
            flex-direction: column;
            height: 100%;
            gap: 20px;
            margin: 50px 0 20px 0;
    }
        
        .card{
        background: $white;
        width: 30%;
        height: 100%;
        border-radius: 10px;
        justify-content: center;

        @include mobile{
            width: 90%;
        }

        h3{
            color: black;
            margin: 30px;
        }

            &__content{
                width: 100%;

                &-text{
                    text-align: center;

                }

                &-prestation{
                    margin-top: 50px;
                    //height: 150px;
                    //La hauteur gere les card dans kiffe ton site pour plus d'uniformité//
                }

                &-price{
                    p{
                        color: $black;
                    }
                }
            }
        }
        
        
    }

}


.pagevente__section{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 50px 0;

    @include mobile{
        margin: 0;
    }
    
    ul{
        list-style: none;
        li{
            display: flex;
            align-items: center;
            margin: 20px 0;
            color: $black;
        } 
    }
}

.pagevente__container {
    h2 {
        margin-top: 100px;
    }
}

.responsive-iframe {
    width: 100%;
    height: 300px;
    border: none;

    @include mobile{
        height: auto;
    }
  }
  
  /* Si nécessaire, utilisez un conteneur pour forcer le ratio */
  .iframe-container {

    @include mobile{
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* Ratio 16:9 */
    height: 0;
    overflow: hidden;
    }
  }
  
  .iframe-container iframe {

    @include mobile{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    }
  }
  
