.booking-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
    max-width: 350px;
    background: $darkgreen;
    border-radius: 10px;
    position: relative;
    color: $white;

    @include tablet {
        width: 300px;
        height: 350px;
    }

    @include mobile{
        width: 90%;
    }

    span{
        position: absolute;
        top: -39px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        background: $black;
        color: $white;
        font-size: 36px;
    }

    a{
        text-decoration: none;
        color: $black;
        font-size: 24px;
    }

   /* p{
        opacity: 0.7;
    }*/
}

.booking-link{
    display: flex;
    text-decoration: none;
    justify-content: center;
    margin-left: 30px;

@include mobile{
    margin-left: 0;
    margin-top: 60px;
}
}