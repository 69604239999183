/*Style footer*/

.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: $black;
    color: $white;
    width: calc(100% - 40px);

    @include mobile{
        margin-top: 0;
    }

    &__media{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        gap: 20px;

        a{ 
            color: $black;
            font-size: 24px;
            transition: transform 0.3s;       
        } 
    }
    .menu{
        justify-content: space-between;
        background: none;
        height: auto;
        width: auto;

        &__burger{
            display: none;
        }

        &__list{
            @include mobile {
                flex-direction: column;
                padding: 0;
            }
        }

        &__item{
            display: flex;

            &-link{
                opacity: 0.9;
                margin: 2.5px;
                color: $white;       
                opacity: 0.9;    
            }

            &:hover {
                border-bottom: none;
                a {
                color: $violet;
                opacity: 1;
                }
            }
        }
    }
    p{
        margin: 0;
    }
}

.span-media {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    background-color: $white;
    border-radius: 50%;
    &:hover {
        background-color: $violet;
        transform: scale(1.30);
    }
}

.footer-mention{
    display: flex;
    align-items: center;
    gap: 20px;
    text-decoration: none;
    margin-top: 30px;

    @include mobile{
        flex-direction: column;
    }
    
    p{

        @include mobile{
        text-align: center;
        }
    }   

    :nth-last-child(2){
        @include mobile {
            display: none;
        }
    }

    a{
        color: white;
        text-decoration: none;
    }

}