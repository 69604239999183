.mesvaleurs{
  display: flex;
  flex-direction: column;
  width: 100%;

  @include tablet{
    margin: 0 0 30px 0;
  }
}

.valeurs {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 90%;

    @include tablet{
      width: 100%;
      margin-top: 20px;
    }

    @include mobile{
      flex-direction: column;
      align-items: center;
    }

  }
  
  .valeur {
    display: flex;
    flex-direction: column;

    align-items: center;
    color: $darkgreen;
    background-color: $green; /* Texte blanc */
    width: 25%;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    height: 250px;

    @include mobile{
      width: 80%;
    }
    p{
      text-align: justify;
      margin: 0;
    }
  }

  .valeur-span{
    color: $green;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -40px;
    //left: -130px;
    background-color: $violet;
    font-size: 24px;
    font-weight: bold;
    border-radius: 10px;
   padding: 20px;
   margin-bottom: 30px;
   width: 80%;
   height: 30px;
   p{
    text-align: center;
    font-size: 24px;
   }
  }
  
  /*.valeur-1 , .valeur-3{
    background-color: $darkgreen;
    color: $pink;

  }
  
  /*.valeur-2 {
    background-color: $violet; 


  }*/
  
 
  