.banner {
    margin-top: 100px;

    @include mobile {
        height: auto;
        margin-top: 0;
    }

    @include tablet {
        margin-top: 50px;
    }

    &__image{
        width: 100%;
        height: 100%;
    }

    &__content{
        padding: 50px;
        //background: rgba(44, 66, 63, 0.7);


        @include tablet{
            padding: 30px;
        }

        @include mobile{
            padding: 20px;
        }


        &-title{
           
            font-family: 'Roboto';
            text-align: left;
            font-size: 64px;
            color: $green;
            opacity: 1;
            font-weight: bold;
            margin: 0;
            
            @include mobile{
                font-size: 36px;
            }
            animation: fadeIn 1.5s ease-in-out forwards;
    
        }

        &-text{
            color: $white;
            font-size: 21px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 50px;

            @include tablet{
                margin-bottom: 30px;
            }

            @include mobile{
                font-size: 16px;
                width: 100%;
            }

            p{
                opacity: 1;
                margin: 5px 20px 0 0;
                text-align: left;

                @include mobile {
                    margin: 0;
                }
            }
        }

        h1 {
            font-family: 'Roboto';
            text-align: left;
            font-size: 36px;
            color:$white;
            width: auto;

            @include tablet{
                font-size: 24px;
            }

            @include mobile {
                font-size: 20px;
            }

            /* Animation CSS pour le fondu */
            animation: fadeIn 1.5s ease-in-out forwards;
            animation-delay: 0.5s; /* 1.5s est la durée de l'animation, ajustez selon vos besoins */
        }


        @keyframes fadeIn {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

    }
}



.banner-prestation{

    display: flex;
    width: 100%;
    margin-top: 100px;

    @include tablet{
        /*height: 400px;*/
        margin-top: 80px;
    }
    
    @include mobile{
        margin-top: 60px;
        height: auto;
    }

    .background{
        flex-direction: row;
        //height: 90vh;

        @include tablet{
            height: 400px;
        }
        @include mobile{
            margin-top: 120px;
            flex-direction: column;
        }
    }

    .banner__content{
        //background: rgba(44, 66, 63, 0.7);
        background: $darkgreen;
        //opacity: 0.2;
    }

    .banner__div{
        display: flex;
        width: 40%;
        height: 100%;
        //position: absolute;
        z-index: 0;

        @include mobile{
            width: 100%;
        }
    }

    .banner__image{
        width: 60%;
        height: 100%;
        object-fit: cover;


        @include mobile{
            width: 100%;
            height: 400px;
        }

    }
    .banner__content-prestation{
        h1{
            width: 90%;
        }
        .banner__content-text{
            width: 90%;
        }
    }

}

.banner-presentation{

    flex-direction: column;
    align-items: flex-end;
    display: flex;
    //justify-content: center;

    @include tablet{
        padding: 30px 0 30px 0;

    }

    @include mobile{
        flex-direction: column;
        align-items: center;
        height: 350px;
        margin-bottom: 50px;
    }



    .banner__div{
        display: flex;
        background-color: $black;
        width: 100%;
        max-height: 300px;
        //position: relative;
        //bottom: 100px;
        //max-height: 370px;
        margin-left: 50px;

        @include mobile{
            padding: 0;
            margin-left: 0;
            max-height: 400px;
        }
        
    }

    .banner__content{

        z-index: 0;
        max-height: 400px;

        @include tablet {
            padding: 30px;
        }

        @include mobile{
            padding: 20px;

        }

        &-text{
            //width: 60%;
            
            @include mobile {
                width: 100%;
            }

            p{
                

                @include tablet {
                    font-size: 16px;
                }
            }
        }

        h1{
            //width: 80%;
            margin-top: 0;

            @include tablet{
                font-size: 24px;
            }

            @include mobile{
                width: 100%;
            }
        }
    }
}

.banner__img-presentation {
    width: 100%;
    height: 80vh;

    @include mobile{
        height: 350px;
        //margin-top: 50px;
    }



}


//A corriger
.banner__buttons {
    display: flex;
    gap: 20px;
    button{
        background-color:$white;
        color: $black;
    }
}

.background{
    display: flex;
    flex-direction: row-reverse;
    //background: rgba(44, 66, 63, 0.7);
    width: 100%;
    position: relative;

    @include mobile {
        flex-direction: column;
        bottom: 100px;

    }

}

