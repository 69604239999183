
.link-bold {
    text-decoration: none;
    font-weight: 800;
    color: $darkgreen;

    &:hover{
        color: $pink;
    }
}

.apropos__container{
//    width: 80%;
    margin: 0 20px;
    display: flex;
    justify-content: center;

    @include mobile{
        flex-direction: column;
    }

    &-img{


        img{
            width: 300px;
            height: 100%;
            opacity: 0.9;

            @include mobile{
                width: 100%;
            }
        }
    }

    &-text{
        color: $darkgreen;
        margin-left: 20px;

        @include mobile {
            margin-left: 0;
        }
    }
}

.home{
    display: flex;
    flex-direction: row;
    background: $darkgreen;
    padding: 0 50px;
    //height: 300px;
    margin: 180px 0 50px 0;

    @include tablet {
        margin: 180px 0 0 0;
    }

    @include mobile {
        flex-direction: column;
        padding: 0;
        height: 100%;
        margin-top: 120px;
    }
    img{
        height: 500px;
        position: relative;
        bottom: 100px;

        @include tablet{
            max-height: 380px;
            bottom: 150px;
        }

        @include mobile{
            margin-top: 50px;
            max-height: 300px;
            bottom: 140px;
        }
    }

    &__services{

        p{
            margin: 10px 0;
            text-align: left;
            width: 100%;
        }

        @include tablet{
            margin-top: 50px;
        }

        @include mobile{
            margin-top: 120px;
        padding: 10px;    
    }
    }

    &__apropos{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $green;

        @include tablet{
            margin-bottom: 50px;
        }

        @include mobile {
            margin: 0 10px;
            position: relative;
            bottom: 100px;
        }

        h2{
            margin: 0 0 30px 0;
            color: $green;

            @include mobile{
                margin: 0;
            }

            @include tablet{
                margin:0 0 10px 0;
            }
        }

        p{
            margin: 10px 50px 10px 50px;
            width: 80%;
            color: $white;
            opacity: 1;

            @include tablet {
                font-size: 16px;
            }

            @include mobile{
                margin: 10px 0;
            }

            &:last-of-type {
                margin-bottom: 30px;
            }
        }
    }
}



  /*Label Marque*/
  .container__label{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    img{
        width: 200px;
        padding: 20px;
        transition: transform 0.3s ;
        &:hover {
            transform: scale(1.10);
        }

        @include mobile{
            width: 100px;
        }
    }
}

.offre{
    &__artisan{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 50px;

        @include tablet {
            margin: 80px 0 30px 0;
        }

        @include mobile{
            flex-direction: column;
        }

        &-text{
            width: 60%;

            @include mobile{
                width: 100%;
            }
            p{
                margin: 10px 0;

                @include mobile{
                    margin: 10px;
                }
            }
        }
    }
}