
  
  .container__projets {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    //margin: 20px 0;
    width: 100%;
    gap: 30px;

    @include tablet {
      width: 100%;
    }

    @include mobile {
      width: 90%;
    }
  }

  .projet__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    background: $darkgreen;
    box-shadow: $box-shadows;
    width: 45%;
    height: auto;
    text-decoration: none;
    margin-right: 10px;
    transition: transform 0.3s ease;
    border-radius: 10px;

    @include tablet {
      width: 45%;
    }

    @include mobile {
      width: 100%;
      margin-right: 0;

    }
  
    &:hover {
      transform: scale(1.05);
    }
  
    img {
      width: 100%;
      height: 300px;
      border-radius: 10px 10px 0 0;

      @include mobile {
        height: 200px;
      }

    }
  
    &-title{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 20px;
      color: white;
      opacity: 0.7;
      border-radius: 5px;
      //height: 50px;
      font-size: 16px;
      font-weight: 400;

    }
  
    p {
      font-size: 14px;
      color: #666;
    }

    button {
      margin-bottom: 20px;
    }
  }
  
  .project__details-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;

/*    @include mobile{
      padding: 20px;
      width: calc(100% - 40px);
    }*/
  }
  
  .project__details-content {
    background: white;
    padding: 20px 40px 40px 40px;
    border-radius: 8px;
    position: relative;
    width: 500px;
    //width: fit-content;
    max-height: 80%;

    @include mobile {
      width: 290px;
    }
  
    .project__details-img{
      max-height: 300px;
      max-width: 100%;
      margin-bottom: 20px;

      @include mobile{
        height: 260px;
        width: auto;
      }
    }

    h3 {
      font-size: 18px;
      color: $darkgreen;
      margin: 10px 0;
    } 

    p{
      color: $darkgreen;
    opacity: 0.85;
    font-size: 14px;
    text-align: left;
    margin: 10px 0 10px 0;
    }
  }


.slick-slider{
  height: 350px;

  @include mobile{
    width: 300px;
  }
  
  .slick-slide{
    display: flex;
    justify-content: center;
    align-items: center;
  div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;


      img{

        display: flex;
        align-items: center;
        justify-content: center;
      }
  }
  }
  button::after{
    background: rgb(182, 182, 182);
  }

  .slick-prev:before, .slick-next:before{
    color: $darkgreen;
  }
}

#nosProjets{
  //margin-top: 100px;
  h2{
    display: none;
  }

  @include mobile{
    margin-top: 100px;
  }
}




