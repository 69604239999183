.header__container {
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    z-index: 100;
    background-color: white;
    box-shadow: $box-shadow-card;
    
    @include tablet{
        background: white;
    }
}

.header__logo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $darkgreen;

    a{
        align-content: center;
        justify-content: center;
        display: flex;
    }
}

.logo {
    margin-left: 50px ;
    height: 70px;

    @include tablet{
        //margin-left: 10px;
        height: 80px;
    }

    @include mobile {
        height: 60px;
        margin-bottom: 20px;
    }
}


 .header__contact-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

 }

.header__contact{
    background: white;
    display: none;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;


     @include mobile {
        width: calc(100% - 20px);
        margin: 0;
        padding: 10px;
        background-color: $darkgreen;
    }

    &-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        @include tablet {
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
        }

        a {
            display: none;

            &:hover {
                color: $violet;
                transform: scale(1.10);
            }

             @include mobile {
                 color: $darkgreen;
            }
        }
    }

    &-media {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        @include tablet {
            justify-content: flex-end;
            align-items: flex-start;
        }

        a {
            color: $darkgreen;
            transition: transform 0.3s;
            margin: 2px;

            @include mobile {
                 color: $green;
            }

            &:hover {
                color: $violet;
                transform: scale(1.30);
            }
        }
    }
}