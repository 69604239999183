@import './utils/_variables';
@import './utils/_mixins';
@import './utils/typographie';
@import './bases/body';
@import './pages/accueil';
@import './pages/apropos';
@import './pages/prestations';
@import './pages/realisations';
@import './pages/contact';
@import './pages/calendly';
@import './pages/resource';
@import './pages/pimpetonsite';
@import './pages/resource';
@import './pages/kiffetonsite';
@import './components/loading_spinner';
@import './components/header';
@import './components/footer';
@import './components/menu';
@import './components/card';
@import './components/banner';
@import './components/form';
@import './components/maintenance_page';
@import './components/modal';
@import './components/button';
@import './components/slide';
@import './components/process.scss';
@import './components/portfolio.scss';
@import './components/gallery.scss';
@import './components/calendly_booking';
@import './components/valeur';
@import './components/testimonies';
@import './components/service_detail';

/*Quel emplacement*/
.sr-only {
    display: none;
}