#process {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;

  @include mobile {
    flex-direction: column-reverse;
    width: 90%;
  }
  
}
  .process__container {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 10px;
  
    @include mobile{
      width: 100%;
      margin-top: 0;
    }
  }
    
  
    .process__card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      margin: 20px;
      color: $black;
      border-radius: 5px;
      

      @include mobile{
        margin: 20px 0;
      }


      &-title{
        display: flex;
        width: calc(100% - 40px);
        height: 50px;
        margin-left: 20px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        h3{
          font-weight: 400;
          color: $black;
          margin: 0 0 0 30px;

          @include mobile{
            margin: 10px;
            font-size: 16px;
          }
        }
      }

      &-text{
        text-align: left;
        width: calc(100% - 140px);
        border-radius: 0 0 5px 5px;
        opacity: 0.7;
        color: $black;
        margin: 10px 0 20px 90px;

        @include mobile{
          margin: 10px 0 0 10px;
          width: calc(100% - 30px);
        }

        p{
          opacity: 1;
          text-align: left;
          margin: 0 0 10px 0;
          color: black;
        }
      }
    }



.process__card-icon{
  font-size: 35px;
  width: 40px;
  color:black;
}

.card__title-div{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.fa-chevron-down{
  color: $black;
  font-size: 24px;
}

.container__processus{
  p{
    margin: 10px;
  }
}