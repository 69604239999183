/*Style body*/

/* Importation de la police Roboto depuis Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oleo+Script:wght@300;400;700&display=swap');


body {
    margin: 0;
    font-family: 'Roboto', sans-serif;

    color: black;
}

section {
    display: flex;
    flex-direction: column;
    align-items: center;
    //padding-bottom: 50px;
    margin: 50px 30px;

    @include tablet{
        margin: 0 10px 30px 10px;;
    }

    @include mobile{
        margin: 10px 0;
    }
}

h1 {
    font-size: 36px;
    width: 60%;
    margin: 20px 0;
    font-family: 'Oleo Script';
    @include mobile {
        font-size: 24px;
    }
}

h2 {
    display: flex;
    color: $darkgreen;
    justify-content: center;
    font-size: 36px;
    text-align: center;
    margin: 0 0 50px 0;

    @include tablet {
        font-size: 24px;
        margin: 30px 0 0 0;
    }

    @include mobile {
        font-size: 21px;
        margin: 30px 20px 0 20px;
    }
}  
   
h3 {
    margin: 10px 50px 0 50px;

    @include tablet{
        margin: 10px 30px 0 30px;
        font-size: 24px;
    }

    @include mobile{
        margin: 10px 10px 0 20px;
    }
}

.section-text {
    margin: 30px 10px;
    text-align: left;
    font-size: 21px;
    color: black;

    @include tablet {
        font-size: 16px;
    }

    @include mobile {
        font-size: 16px;
        margin: 20px 20px 30px 20px;
    }
}

p {
    text-align: left;
    font-size: 18px;
    margin: 30px 50px;

    @include tablet{
        font-size: 16px;
        margin: 30px 10px;
    }

    @include mobile{
        margin: 20px 10px;
    }
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.container__body{
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
}
.main{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-color{

    text-decoration: none;
  
    
}
    


.text-hover {
    &:hover {
        color: $darkviolet;
      } 
}   
  

