#contact{
    padding-bottom: 0;
    margin-top: 100px;

    @include mobile{
        margin-top: 50px;
    }
}

#contact__section {
    display: flex;
    justify-content: center;
    width: 100%;

    @include mobile{
        margin-bottom: 50px;
    }
}

.contact__container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    width: 80%;
    margin-top: 30px;

    @include tablet{
        width: 100%;
        gap: 10px;
    }
  
    @include mobile {
        flex-direction: column;
        align-items: center;
        gap: 30px;
        width: 90%;
    }
}

.contact__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $darkgreen;
    color: $white;
    width: 30%;
    padding: 10px;
    border-radius: 5px;
    min-width: 180px;
    font-size: 30px;
    box-shadow: $box-shadow-card;
    transition: transform 0.3s;
    &:hover{
        transform: scale(1.05);
    }

    @include mobile {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 80%;
        height: 30px;
        box-shadow: none;

    }
    &-title {
        display: none;
    }
    p, a {
        text-align: center;
        color: $green;
        //opacity: 0.7;
        font-size: 18px;
        font-weight: bold;
        margin: 20px 0;

        @include tablet{
            font-weight: normal;
            font-size: 16px;
        }

        @include mobile {
            margin-left: 0;
        }
    }

    a{
        text-decoration: none;
        text-align: center;
    
    }

    span{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        background:$black;
        border-radius: 50%;
        position: relative;
        top: -50px;

        @include mobile{
            width: 50px;
            height: 50px;
            top: 0;
            font-size: 20px;
            left: -30px;
        }
    }
}
