/* Card Styling */
/*Card__service*/
.container__card{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    //padding: 20px;
    border-radius: 10px;
    width: 90%;
    margin-top: 50px;

    @include mobile {
        flex-direction: column;
        align-items: center;
        padding: 0;
        margin-top: 20px;
    }
}

/*.card__content-img{
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0 0;
}*/

.card-link{
    margin: 50px 0;
    width: 45%;
    text-decoration: none;

    @include mobile{
        margin: 20px 0;

        width: 100%;
    }
    .card{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background: $darkgreen;
        box-shadow: $box-shadow-card;

        &__content{
            padding: 0;
            color: white;

            &-icon{
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                bottom: 50px;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background: $violet;
                color: $pink;

                .service-icon{
                    font-size: 60px;
                    
                }
            }

            &-text{

                margin-bottom: 10px;
                width: 100%;

                div{
                    padding:0 20px;
                }

                ul{
                    @include mobile{
                        padding-left: 20px;
                    }
                }


                li{
                    margin: 0 0 10PX 0;
                    list-style: none;
                }
            }
            h3{
                height: 50px;
                color:$white;
            }
        }
    }
}
.card {
    position: relative; /* Pour que la bannière soit positionnée par rapport à la card */
    display: flex;
    flex-direction: row;
    transition: transform 0.3s;
    width: 30%;
    opacity: 1;
    border-radius: 10px;

    @include mobile {
        width: 100%;
    }

    &:hover {
        transform: scale(1.05);
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        text-align: center;
        padding: 10px;
        color: $green;

        @include mobile {
            width: 100%;
        }

        &-text {
            width: 100%;
            opacity: 0.65;
            margin-bottom: 20px;
            text-align: left;
            padding-left: 20px;
        }

        h3 {
            margin: 10px;
            color: $green;
        }

        &-prestation {
            text-align: left;
            ul {
                padding: 0;
            }
            li {
                display: flex;
                align-items: flex-start;
                list-style: none;
                margin: 10px 0;
                div {
                    width: 20px;
                    margin-right: 20px;
                    .icon-check {
                        opacity: 1;
                    }
                    .icon-times {
                        color: $darkviolet;
                        opacity: 1;
                    }
                }
                p {
                    width: 80%;
                    opacity: 0.9;
                    margin: 0;
                    text-align: left;
                }
            }
        }

        &-price {
            p {
                color: $green;
                font-weight: bold;
                opacity: 1;

                @include mobile {
                    margin: 20px;
                }
            }
        }
    }
}

.services-autre{
    .card{
        width: auto;
    }
}

.service-image{
    width: 100%;
    border-radius: 10px 10px 0 0;
}

/* Card Promotion Banner */
.card-promotion-banner {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    top: -50px; /* Distance depuis le haut de la card */
    left: 0px; /* Distance depuis la gauche de la card */
    background-color: $darkviolet; /* Couleur de fond pour la promotion */
    color: white; /* Couleur du texte */
    padding: 5px 10px; /* Espacement interne */
    font-size: 14px; /* Taille du texte */
    font-weight: bold; /* Style du texte */
    transform: rotate(-10deg) translateX(-20px); /* Inclinaison et position initiale */
    border-radius: 5px; /* Coins légèrement arrondis */
    z-index: 10; /* S'assurer que la bannière est au-dessus */
    animation: slide-pulse 4s infinite; /* Animation personnalisée */
    height: 80px;
    width: 140px;

    @include mobile{
        height: 60px;
        width: 120px;
        top: -15px;
}
}

/* Animation slide-pulse */
@keyframes slide-pulse {
    0% {
        opacity: 0;
        transform: rotate(-10deg)  scale(0.8); /* Départ hors du cadre */
    }
    5% {
        opacity: 1;
        transform: rotate(-10deg) translateX(0) scale(1); /* Glisse en place */
    }
    25% {
        transform: rotate(-10deg) translateX(0) scale(1.1); /* Légère pulsation */
    }
    45% {
        opacity: 1;
        transform: rotate(-10deg) translateX(0) scale(1); /* Retour à la taille normale */
    }

    55% {
        opacity: 1;
        transform: rotate(-10deg) translateX(0) scale(1); /* Glisse en place */
    }
    75% {
        transform: rotate(-10deg) translateX(0) scale(1.1); /* Légère pulsation */
    }
    90% {
        opacity: 1;
        transform: rotate(-10deg) translateX(0) scale(1); /* Retour à la taille normale */
    }
    100% {
        opacity: 0;
        transform: rotate(-10deg) scale(0.8); /* Disparaît en glissant */
    }
}

