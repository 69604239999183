//breakpoints
$breakpoints: (
  mobile: 768px,
  tablet: 1024px,
  desktop: 1200px,
);

//Colors
$black: #000000;

$darkgreen: #2c423f;
$green: #efe3dd;
$white: white;
$darkviolet: #f25f5c;
$violet: #c78b6f;
$pink: #f5d0c5;

$darkgreen-opacity: rgba(30, 43, 59, 0.3);


$box-shadows: 3px 3px 5px rgb(226, 221, 221);
$box-shadows-btn: 0px 0px 5px $pink;
$box-shadows: 0px 0px 5px $darkgreen;
$box-shadows-hover: 0px 0px 5px $pink;
$box-shadow-card: 0px 1px 2px $darkgreen;
/*$box-shadows-hover: 0px 5px 10px rgb(226, 230, 173);*/

$linearGradient: linear-gradient(to bottom right, lighten($green, 5), lighten($darkgreen, 5));
$linearGradient2: linear-gradient(to bottom right, $green, transparent);
$linearGradient3: linear-gradient(to bottom right, darken($pink, 5), lighten($violet, 5));
$linearGradient4: linear-gradient(to bottom right, lighten($pink, 5), lighten($violet, 10));