button {
    width: 160px;
    height: 40px;
    font-family: 'Roboto';
    font-weight: bold;
    background: $black;
    color: $white;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    transition: transform 0.3s;
    border: none;

    &:hover {
        color: $white;
        //background-color: $black;
        transform: scale(1.1);

        &::after {
            //opacity: 0.75;
            background-color: $darkviolet;
        }
    }
    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

//        opacity: 0.75;
        z-index: -1;
        border-radius: 10px;
        transition : opacity 250ms;
        //box-shadow: $box-shadows-hover;
    }   
}
  
  /* Style pour le bouton "Valider" */

  .buttons {
    display: flex;
    gap: 10px;

    
}
    .form__button{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        //border: none;

        cursor: pointer;
        font-size: 16px;

        &::after {
            content: "";
            border-radius: 10px;
        }
    }

    .close-btn {
        position: absolute;
        width: 40px;
        top: 10px;
        right: 10px;
        color: #fff;
        padding: 8px 12px;
        border: none;
        cursor: pointer;
        border-radius: 4px;

        &::after{
            border-radius: 4px;
        }
      }

