#service{

    @include mobile{
        margin-top: 80px;
    }
}

.container__services{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

    &-div{
        display: flex;
        flex-direction: column;

    }

    .container__card{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        //background: $violet;
        width: 100%;
        margin: 50px;

        @include tablet{
            display: flex;
            //align-items: flex-start;
            margin: 30px;
        }

        @include mobile{
            flex-wrap: wrap;
            margin: 10px;
        }

        .service-div{
            background-color: black;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 100px);
            padding: 50px;
            //background: $darkgreen;
            //padding: 20px;

            @include tablet {
                flex-direction: column;
            }

            @include mobile{
                width: calc(100% - 40px);
                padding: 20px;
            }

            .service-detail{

                h3{
                    color: white
                };
                
                flex-direction: column;
                align-items: left;
                ul{
                    margin-top: 50px;
                    padding: 20px;

                    @include mobile{
                        padding: 0 0 10px 10px;
                    }
                }

                li{
                    list-style: none;
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    //background: $violet;
                    color: white;
                    border-radius: 20px;
                    padding-right: 10px;

                    @include mobile{
                        padding-right: 0;
                    }

                }
            }

            .container__card-div{
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 20px;
                width: 100%;

                @include tablet {
                    justify-content: center;
                    flex-direction: row;
                }

                @include mobile{

                    flex-direction: column;
                }
            }
        }
        
        .card{
            background: $white;
            //color: $darkgreen;
            width: 45%;
            height: 100%;
            border-radius: 10px;

            @include mobile{
                width: 100%;
            }

            &:hover{
                transform: none;
            }

            &__content{
                width: auto;
                color: $black;
                h3{
                    color: black;
                    margin: 30px;
                }

                &-text{
                    text-align: center;
                    //color: $green;
                    opacity: 1;
                    }

                &-price{
                    p{
                    color: $black;
                    }
                }
            }
        }
    }
}  


    .container__section{
        display: flex;
        flex-direction: row;
        align-items: center;

        @include mobile{
            flex-direction: column;
        }

        img{
            width: 45%;

            @include mobile{
                width: 90%;
                margin-bottom: 30px;
            }
        }

    h3{
        color: $darkviolet;
    }

    &-text{
        text-align: left;
        font-size: 18px;
        opacity: 1;

        @include mobile{
            font-size: 16px;
        }
    }

    ul{
        padding: 20px 50px;

        @include mobile{
            padding: 0 20px;
        }
    }

    li{
        display: flex;
        align-items: center;
        list-style-type: none;
        font-size: 18px;
        margin-bottom: 20px;

        @include mobile{
            align-items: flex-start;
            margin-bottom: 10px;
            font-size: 16px;
        }
    }

    &-icon{
        color : $darkviolet;
        margin-right: 20px;

        @include mobile{
            margin-right: 10px;
        }
    }

}

/*.service__img{
    width: 40%;
}*/


.icon-circle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    border-radius: 50%;
    background-color: $black;
    width: 40px;
    height: 40px;
    font-size: 18px;
    color: $darkviolet;

    @include mobile{
        width: 30px;
        height: 30px;
        font-size: 14px;
        margin-right: 10px;
    }
}

.icon-div{
    width: 80%;
    font-size: 18px;

    @include tablet {
        font-size: 16px;
    }
}

.customer-type{
    font-size: 36px;
    color: $darkviolet;

    @include tablet{
        font-size: 24px;
    }
}

/*.services-autre{
    .container__card{
        h3{
            display: none;
        }
    }
}*/

/*.service-btn{
    display: flex;
    gap: 20px;
}*/