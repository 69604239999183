.calendly-page {
    max-width: 1440px;
    
}

.PjPBl3MPKsCvWXT51KHv._Jtap3zpvOIYtGbqACME{
    border: 1px solid black;
}
.zcal-page{
    h1{
        display: none;
        margin-top: 50px;
    }
}

.zcal-inline-widget{
    margin-top: 100px;
}