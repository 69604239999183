.toggle-button {
    color: blue;
    cursor: pointer;
    font-weight: bold;
  }
  
  .advice-container {
    display: flex;
    margin-bottom: 50px;
    gap: 20px;
    justify-content: space-around;

    @include mobile{
      margin: 50px 0;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }
  
  .testimonial-item {
    width: 250px;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
  }
  
  .testimonial-text {
    font-style: italic;
    margin: 0;
  }
  
  .testimonial-author {
    margin: 10px 0;
    font-weight: bold;
  }
  
  .testimonial-stars {
    margin-top: 10px;
  }
  
  .star-filled {
    color: gold;
  }
  
  .star-empty {
    color: lightgray;
  }
  